export default [
  {
    path: '/e-commerce',
    meta: {
      service: 'e-commerce',
      resource: 'E-commerce-dashboard',
      action: 'read',
    },
    redirect: { name: 'e-commerce-dashboard' },
  },
  {
    path: '/e-commerce/dashboard',
    name: 'e-commerce-dashboard',
    component: () => import('../../views/services/eCommerce/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'e-commerce',
      resource: 'E-commerce-dashboard',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/users/ability/:id',
    name: 'e-commerce-users-ability',
    component: () => import('../../views/services/eCommerce/users/ability/Settings.vue'),
    meta: {
      pageTitle: 'Users Ability',
      service: 'e-commerce',
      resource: 'E-commerce-users-ability',
      action: 'read',
      breadcrumb: [
        {
          text: 'Staff',
          to: '/e-commerce/users/staff',
        },
        {
          text: 'Ability',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-users-staff',
    },
  },
  {
    path: '/e-commerce/users/admin',
    name: 'e-commerce-users-admin',
    component: () => import('../../views/services/eCommerce/users/Users.vue'),
    meta: {
      pageTitle: 'Users Admin',
      service: 'e-commerce',
      resource: 'E-commerce-users-admin',
      action: 'read',

    },
  },
  {
    path: '/e-commerce/users/staff',
    name: 'e-commerce-users-staff',
    component: () => import('../../views/services/eCommerce/users/Users.vue'),
    meta: {
      pageTitle: 'Users Staff',
      service: 'e-commerce',
      resource: 'E-commerce-users-staff',
      action: 'read',

    },
  },
  {
    path: '/e-commerce/permission-in-app',
    name: 'e-commerce-permission-in-app',
    component: () => import('../../views/services/eCommerce/permissionInApp/PermissionInApp.vue'),
    meta: {
      pageTitle: 'Permission in app',
      service: 'e-commerce',
      resource: 'E-commerce-permission-in-app',
      action: 'read',

    },
  },
  {
    path: '/e-commerce/permission-in-app/:id',
    name: 'e-commerce-permission-in-app-view',
    component: () => import('../../views/services/eCommerce/permissionInApp/pages/View.vue'),
    meta: {
      pageTitle: 'View permission in app',
      service: 'e-commerce',
      resource: 'E-commerce-permission-in-app-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Permission in app',
          to: '/e-commerce/permission-in-app',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-permission-in-app',
    },
  },
  {
    path: '/e-commerce/product',
    name: 'e-commerce-product',
    component: () => import('../../views/services/eCommerce/product/Product.vue'),
    meta: {
      pageTitle: 'Product',
      service: 'e-commerce',
      resource: 'E-commerce-product',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/product/view/:id',
    name: 'e-commerce-product-view',
    component: () => import('../../views/services/eCommerce/product/ViewProduct.vue'),
    meta: {
      pageTitle: 'Product View',
      service: 'e-commerce',
      resource: 'E-commerce-product-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/product',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product',
    },
  },
  {
    path: '/e-commerce/product/stock/:id',
    name: 'e-commerce-product-stock',
    component: () => import('../../views/services/eCommerce/product/stock/Stock.vue'),
    meta: {
      pageTitle: 'Product Stock',
      service: 'e-commerce',
      resource: 'E-commerce-product-stock',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/product',
        },
        {
          text: 'Stock',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product',
    },
  },
  {
    path: '/e-commerce/shiping/wholesale/box',
    name: 'e-commerce-shiping-wholesale-box',
    component: () => import('../../views/services/eCommerce/shiping/wholeSaleBox/WholeSaleBox.vue'),
    meta: {
      pageTitle: 'Wholesale box',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-wholesale-box',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/shiping/customer',
    name: 'e-commerce-shiping-customer',
    component: () => import('../../views/services/eCommerce/shiping/shiping.vue'),
    meta: {
      pageTitle: 'Customers choose',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-customer',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/shiping/:id/customer',
    name: 'e-commerce-shiping-by-table-customer',
    component: () => import('../../views/services/eCommerce/shiping/table.vue'),
    meta: {
      pageTitle: 'Table Customers choose',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-by-table-customer',
      action: 'read',
      breadcrumb: [
        {
          text: 'Customers choose',
          to: '/e-commerce/shiping/customer',
        },
        {
          text: 'Table Customers choose',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-shiping-customer',
    },
  },
  {
    path: '/e-commerce/shiping/order',
    name: 'e-commerce-shiping-by-order',
    component: () => import('../../views/services/eCommerce/shiping/shiping.vue'),
    meta: {
      pageTitle: 'Shiping By Order',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-by-order',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/shiping/:id/order',
    name: 'e-commerce-shiping-by-table-order',
    component: () => import('../../views/services/eCommerce/shiping/table.vue'),
    meta: {
      pageTitle: 'Table Order',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-by-table-order',
      action: 'read',
      breadcrumb: [
        {
          text: 'Shiping By Order',
          to: '/e-commerce/shiping/order',
        },
        {
          text: 'Table Order',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-shiping-by-order',
    },
  },
  {
    path: '/e-commerce/shiping/weight',
    name: 'e-commerce-shiping-by-weight',
    component: () => import('../../views/services/eCommerce/shiping/shiping.vue'),
    meta: {
      pageTitle: 'Shiping By Weight',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-by-weight',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/shiping/:id/weight',
    name: 'e-commerce-shiping-by-table-weight',
    component: () => import('../../views/services/eCommerce/shiping/table.vue'),
    meta: {
      pageTitle: 'Table Weight',
      service: 'e-commerce',
      resource: 'E-commerce-shiping-by-table-weight',
      action: 'read',
      breadcrumb: [
        {
          text: 'Shiping By Weight',
          to: '/e-commerce/shiping/weight',
        },
        {
          text: 'Table Weight',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-shiping-by-weight',
    },
  },
  {
    path: '/e-commerce/product/managecategory/:id',
    name: 'e-commerce-product-managecategory',
    component: () => import('../../views/services/eCommerce/product/manageCategory/ManageCategory.vue'),
    meta: {
      pageTitle: 'Manage Product Categories',
      service: 'e-commerce',
      resource: 'E-commerce-product-managecategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/product',
        },
        {
          text: 'Categories',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product',
    },
  },
  {
    path: '/e-commerce/product/import',
    name: 'e-commerce-product-import',
    component: () => import('../../views/services/eCommerce/product/importProduct.vue'),
    meta: {
      pageTitle: 'Import Product',
      service: 'e-commerce',
      resource: 'E-commerce-product-import',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/product',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product',
    },
  },
  {
    path: '/e-commerce/productCategory',
    name: 'e-commerce-productCategory',
    component: () => import('../../views/services/eCommerce/productCategory/ProductCategory.vue'),
    meta: {
      pageTitle: 'Product Category',
      service: 'e-commerce',
      resource: 'E-commerce-productCategory',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/productCategory/sort',
    name: 'e-commerce-productCategory-sort',
    component: () => import('../../views/services/eCommerce/productCategory/Sort.vue'),
    meta: {
      pageTitle: 'Product Category Sort',
      service: 'e-commerce',
      resource: 'E-commerce-productCategory-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product Category',
          to: '/e-commerce/productCategory',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-productCategory',
    },
  },
  {
    path: '/e-commerce/productCategory/import',
    name: 'e-commerce-productCategory-import',
    component: () => import('../../views/services/eCommerce/productCategory/importProductCategory.vue'),
    meta: {
      pageTitle: 'Import Product Category',
      service: 'e-commerce',
      resource: 'E-commerce-productCategory-import',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product Category',
          to: '/e-commerce/productCategory',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-productCategory',
    },
  },
  {
    path: '/e-commerce/promotion',
    name: 'e-commerce-promotion',
    component: () => import('../../views/services/eCommerce/promotion/Promotion.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'e-commerce',
      resource: 'E-commerce-promotion',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/promotion/:id',
    name: 'e-commerce-promotion-view',
    component: () => import('../../views/services/eCommerce/promotion/product/Product.vue'),
    meta: {
      pageTitle: 'Promotion View',
      service: 'e-commerce',
      resource: 'E-commerce-promotion-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Promotion',
          to: '/e-commerce/promotion',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-promotion',
    },
  },
  {
    path: '/e-commerce/coupon',
    name: 'e-commerce-coupon',
    component: () => import('../../views/services/eCommerce/coupon/Coupon.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'e-commerce',
      resource: 'E-commerce-coupon',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/coupon-category',
    name: 'e-commerce-coupon-category',
    component: () => import('../../views/services/eCommerce/coupon/category/CouponCategory.vue'),
    meta: {
      pageTitle: 'Coupon Category',
      service: 'e-commerce',
      resource: 'E-commerce-coupon-category',
      action: 'read',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/e-commerce/coupon',
        },
        {
          text: 'Category',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-coupon',
    },
  },
  {
    path: '/e-commerce/flash/sale',
    name: 'e-commerce-flash-sale',
    component: () => import('../../views/services/eCommerce/flashSale/FlashSale.vue'),
    meta: {
      pageTitle: 'Flash Sale',
      service: 'e-commerce',
      resource: 'E-commerce-flash-sale',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/promote',
    name: 'e-commerce-promote',
    component: () => import('../../views/services/eCommerce/promote/Promote.vue'),
    meta: {
      pageTitle: 'Promote',
      service: 'e-commerce',
      resource: 'E-commerce-promote',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/promote/view/:id',
    name: 'e-commerce-promote-view',
    component: () => import('../../views/services/eCommerce/promote/View/PromoteView.vue'),
    meta: {
      pageTitle: 'View Promote',
      service: 'e-commerce',
      resource: 'E-commerce-promote-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Promote',
          to: '/e-commerce/promote',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-promote',

    },
  },
  {
    path: '/e-commerce/promote/view/html/:id',
    name: 'e-commerce-product-view-html',
    component: () => import('../../views/services/eCommerce/promote/View/PromoteHTML.vue'),
    meta: {
      pageTitle: 'HTML Promote',
      service: 'e-commerce',
      resource: 'E-commerce-promote-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Promote',
          to: '/e-commerce/promote',
        },
        {
          text: 'HTML',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-promote',

    },
  },
  {
    path: '/e-commerce/pushNotification',
    name: 'e-commerce-push-notification',
    component: () => import('../../views/services/eCommerce/pushNotification/PushNotification.vue'),
    meta: {
      pageTitle: 'Push Notification',
      service: 'e-commerce',
      resource: 'E-commerce-push-notification',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/notification',
    name: 'e-commerce-notification',
    component: () => import('../../views/services/eCommerce/notification/Notification.vue'),
    meta: {
      pageTitle: 'Notification',
      service: 'e-commerce',
      resource: 'E-commerce-notification',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/calendar/content',
    name: 'e-commerce-calendar-content',
    component: () => import('../../views/services/eCommerce/calendarContent/CalendarContent.vue'),
    meta: {
      pageTitle: 'Calendar Content',
      service: 'e-commerce',
      resource: 'E-commerce-calendar-content',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/order',
    name: 'e-commerce-order',
    component: () => import('../../views/services/eCommerce/order/order/Order.vue'),
    meta: {
      pageTitle: 'Order',
      service: 'e-commerce',
      resource: 'E-commerce-order',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/confirm/payment',
    name: 'e-commerce-confirm-payment',
    component: () => import('../../views/services/eCommerce/order/confirmPayment/ConfirmPayment.vue'),
    meta: {
      pageTitle: 'Confirm Payment',
      service: 'e-commerce',
      resource: 'E-commerce-confirm-payment',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/affiliate/product/group',
    name: 'e-commerce-affiliate-product-group',
    component: () => import('../../views/services/eCommerce/affiliate/productGroup/ProductGroup.vue'),
    meta: {
      pageTitle: 'Product Group',
      service: 'e-commerce',
      resource: 'E-commerce-affiliate-product-group',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/member',
    name: 'e-commerce-member',
    component: () => import('../../views/services/eCommerce/member/Member.vue'),
    meta: {
      pageTitle: 'Member',
      service: 'e-commerce',
      resource: 'E-commerce-member',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/member/import',
    name: 'e-commerce-member-import',
    component: () => import('../../views/services/eCommerce/member/importMember.vue'),
    meta: {
      pageTitle: 'Import member',
      service: 'e-commerce',
      resource: 'E-commerce-member-import',
      action: 'read',
      breadcrumb: [
        {
          text: 'Member',
          to: '/e-commerce/member',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-member',
    },
  },
  {
    path: '/e-commerce/credit',
    name: 'e-commerce-credit',
    component: () => import('../../views/services/eCommerce/creditSystem/credit/Credit.vue'),
    meta: {
      pageTitle: 'Credit',
      service: 'e-commerce',
      resource: 'E-commerce-credit',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/credit/confirm/payment',
    name: 'e-commerce-credit-confirm-payment',
    component: () => import('../../views/services/eCommerce/creditSystem/confirmPayment/ConfirmPayment.vue'),
    meta: {
      pageTitle: 'Credit Confirm Payment',
      service: 'e-commerce',
      resource: 'E-commerce-credit-confirm-payment',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/credit/balance/member',
    name: 'e-commerce-credit-balance-member',
    component: () => import('../../views/services/eCommerce/creditBalance/CreditBalance.vue'),
    meta: {
      pageTitle: 'Credit Balance Member',
      service: 'e-commerce',
      resource: 'E-commerce-credit-balance-member',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/questionnaire',
    name: 'e-commerce-questionnaire',
    component: () => import('../../views/services/eCommerce/questionnaire/Questionnaire.vue'),
    meta: {
      pageTitle: 'Questionnaire',
      service: 'e-commerce',
      resource: 'E-commerce-questionnaire',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/questionnaire/:id',
    name: 'e-commerce-questionnaire-view',
    component: () => import('../../views/services/eCommerce/questionnaire/QuestionnaireView.vue'),
    meta: {
      pageTitle: 'Questionnaire View',
      service: 'e-commerce',
      resource: 'E-commerce-questionnaire-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Questionnaire',
          to: '/e-commerce/questionnaire',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-questionnaire',
    },
  },
  {
    path: '/e-commerce/questionnaire/answer/:questionnaireId',
    name: 'e-commerce-questionnaire-answer',
    component: () => import('../../views/services/eCommerce/questionnaire/Answer/QuestionnaireAnswer.vue'),
    meta: {
      pageTitle: 'Questionnaire Answer',
      service: 'e-commerce',
      resource: 'E-commerce-questionnaire-answer',
      action: 'read',
      breadcrumb: [
        {
          text: 'Questionnaire',
          to: '/e-commerce/questionnaire',
        },
        {
          text: 'Answer',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-questionnaire',
    },
  },
  {
    path: '/e-commerce/questionnaire/answer/:questionnaireId/:id',
    name: 'e-commerce-questionnaire-answer-view',
    component: () => import('../../views/services/eCommerce/questionnaire/Answer/QuestionnaireViewAnswer.vue'),
    meta: {
      pageTitle: 'Questionnaire Answer View',
      service: 'e-commerce',
      resource: 'E-commerce-questionnaire-answer-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Questionnaire',
          to: '/e-commerce/questionnaire',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-questionnaire',
    },
  },
  {
    path: '/e-commerce/menu',
    name: 'e-commerce-menu',
    component: () => import('../../views/services/eCommerce/menu/Menu.vue'),
    meta: {
      pageTitle: 'Menu',
      service: 'e-commerce',
      resource: 'E-commerce-menu',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/menu/sort',
    name: 'e-commerce-menu-sort',
    component: () => import('../../views/services/eCommerce/menu/Sort.vue'),
    meta: {
      pageTitle: 'Menu Sort',
      service: 'e-commerce',
      resource: 'E-commerce-menu-sort',
      action: 'read',
      breadcrumb: [
        {
          text: 'Menu',
          to: '/e-commerce/menu',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-menu',
    },
  },
  {
    path: '/e-commerce/menu/:id',
    name: 'e-commerce-menu-view',
    component: () => import('../../views/services/eCommerce/menu/subMenu/SubMenu.vue'),
    meta: {
      pageTitle: 'Menu View',
      service: 'e-commerce',
      resource: 'E-commerce-menu-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Menu',
          to: '/e-commerce/menu',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-menu',
    },
  },
  {
    path: '/e-commerce/menu/sub/:idMenu/:id',
    name: 'e-commerce-menu-sub-view',
    component: () => import('../../views/services/eCommerce/menu/subMenu/ViewSubMenu.vue'),
    meta: {
      pageTitle: 'Menu Sub View',
      service: 'e-commerce',
      resource: 'E-commerce-menu-sub-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Menu',
          to: '/e-commerce/menu',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-menu',
    },
  },
  {
    path: '/e-commerce/slide',
    name: 'e-commerce-slide',
    component: () => import('../../views/services/eCommerce/slide/Slide.vue'),
    meta: {
      pageTitle: 'Slide',
      service: 'e-commerce',
      resource: 'E-commerce-slide',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/upload/file',
    name: 'e-commerce-upload-file',
    component: () => import('../../views/services/eCommerce/uploadFile/UploadFile.vue'),
    meta: {
      pageTitle: 'Upload File',
      service: 'e-commerce',
      resource: 'E-commerce-upload-file',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/images/settings',
    name: 'e-commerce-images-settings',
    component: () => import('../../views/services/eCommerce/settings/images/ImagesSetting.vue'),
    meta: {
      pageTitle: 'Images Settings',
      service: 'e-commerce',
      resource: 'E-commerce-images-settings',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/images/settings/graphics/store',
    name: 'e-commerce-images-settings-graphics-store',
    component: () => import('../../views/services/eCommerce/settings/images/graphicsStore/GraphicsStore.vue'),
    meta: {
      pageTitle: 'Graphics Store',
      service: 'e-commerce',
      resource: 'E-commerce-images-settings-graphics-store',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/e-commerce/images/settings',
        },
        {
          text: 'Graphics Store Setting',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-images-settings',
    },
  },
  {
    path: '/e-commerce/images/settings/logo',
    name: 'e-commerce-images-settings-logo',
    component: () => import('../../views/services/eCommerce/settings/images/logo/Logo.vue'),
    meta: {
      pageTitle: 'Logo Setting',
      service: 'e-commerce',
      resource: 'E-commerce-images-settings-logo',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/e-commerce/images/settings',
        },
        {
          text: 'Logo Setting',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-images-settings',
    },
  },
  {
    path: '/e-commerce/images/settings/splash',
    name: 'e-commerce-images-settings-splash',
    component: () => import('../../views/services/eCommerce/settings/images/splashScreen/SplashScreen.vue'),
    meta: {
      pageTitle: 'Splash Setting',
      service: 'e-commerce',
      resource: 'E-commerce-images-settings-splash',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/e-commerce/images/settings',
        },
        {
          text: 'Splash Setting',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-images-settings',
    },
  },
  {
    path: '/e-commerce/images/settings/slide',
    name: 'e-commerce-images-settings-slide',
    component: () => import('../../views/services/eCommerce/settings/images/slideScreen/SlideScreen.vue'),
    meta: {
      pageTitle: 'Slide Setting',
      service: 'e-commerce',
      resource: 'E-commerce-images-settings-slide',
      action: 'read',
      breadcrumb: [
        {
          text: 'Images Setting',
          to: '/e-commerce/images/settings',
        },
        {
          text: 'Slide Setting',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-images-settings',
    },
  },
  {
    path: '/e-commerce/application/settings',
    name: 'e-commerce-application-settings',
    component: () => import('../../views/services/eCommerce/settings/applicationSettings/ApplicationSettings.vue'),
    meta: {
      pageTitle: 'Application Settings',
      service: 'e-commerce',
      resource: 'E-commerce-application-settings',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/set/application/color',
    name: 'e-commerce-set-application-color',
    component: () => import('../../views/services/eCommerce/settings/setApplicationColor/SetApplicationColor.vue'),
    meta: {
      pageTitle: 'Set Application Color',
      service: 'e-commerce',
      resource: 'E-commerce-set-application-color',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/application/settings/payment/:name',
    name: 'e-commerce-application-settings-payment-name',
    component: () => import('../../views/services/eCommerce/settings/applicationSettings/paymentSetting/PaymentSetting.vue'),
    meta: {
      pageTitle: 'Application Settings Payment',
      service: 'e-commerce',
      resource: 'E-commerce-application-settings-payment-name',
      action: 'read',
      breadcrumb: [
        {
          text: 'Set Up Payment System',
          to: '/e-commerce/set/up/payment/system',
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-set-up-payment-system',
    },
  },
  {
    path: '/e-commerce/setup/system',
    name: 'e-commerce-set-up-system',
    component: () => import('../../views/services/eCommerce/settings/setupSystem/SetUpSystem.vue'),
    meta: {
      pageTitle: 'Set Up System',
      service: 'e-commerce',
      resource: 'E-commerce-set-up-system',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/set/support',
    name: 'e-commerce-set-support',
    component: () => import('../../views/services/eCommerce/settings/setSupport/SetSupport.vue'),
    meta: {
      pageTitle: 'Set Support',
      service: 'e-commerce',
      resource: 'E-commerce-set-support',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/set/up/payment/system',
    name: 'e-commerce-set-up-payment-system',
    component: () => import('../../views/services/eCommerce/settings/setUpPaymentSystem/SetUpPaymentSystem.vue'),
    meta: {
      pageTitle: 'Set Up Payment System',
      service: 'e-commerce',
      resource: 'E-commerce-set-up-payment-system',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/set/up/currency',
    name: 'e-commerce-set-up-currency',
    component: () => import('../../views/services/eCommerce/settings/setUpCurrency/SetUpCurrency.vue'),
    meta: {
      pageTitle: 'Set Up Currency',
      service: 'e-commerce',
      resource: 'E-commerce-set-up-currency',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/terms-and-privacy',
    name: 'e-commerce-terms-and-privacy',
    component: () => import('../../views/services/eCommerce/settings/termsAndPrivacy/TermsAndPrivacy.vue'),
    meta: {
      pageTitle: 'Terms and privacy',
      service: 'e-commerce',
      resource: 'E-commerce-terms-and-privacy',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/main',
    name: 'e-commerce-main-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/mainWarehouse/MainWarehouse.vue'),
    meta: {
      pageTitle: 'Main Warehouse',
      service: 'e-commerce',
      resource: 'E-commerce-main-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/product',
    name: 'e-commerce-product-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/product/Product.vue'),
    meta: {
      pageTitle: 'Product',
      service: 'e-commerce',
      resource: 'E-commerce-product-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/product/:id',
    name: 'e-commerce-product-view-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/product/View/View.vue'),
    meta: {
      pageTitle: 'View Product',
      service: 'e-commerce',
      resource: 'E-commerce-product-view-warehouse',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/warehouse/product',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product-warehouse',
    },
  },
  {
    path: '/e-commerce/warehouse/product/transfer/:id',
    name: 'e-commerce-product-transfer-view-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/product/Transfer/Transfer.vue'),
    meta: {
      pageTitle: 'Create a product transfer list',
      service: 'e-commerce',
      resource: 'E-commerce-product-transfer-view-warehouse',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/warehouse/product',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product-warehouse',
    },
  },
  {
    path: '/e-commerce/warehouse/product/warehouse/import',
    name: 'e-commerce-product-import-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/product/Import/Import.vue'),
    meta: {
      pageTitle: 'Import Product',
      service: 'e-commerce',
      resource: 'E-commerce-product-import-warehouse',
      action: 'read',
      breadcrumb: [
        {
          text: 'Product',
          to: '/e-commerce/warehouse/product',
        },
        {
          text: 'Import',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-product-warehouse',
    },
  },
  {
    path: '/e-commerce/warehouse',
    name: 'e-commerce-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/warehouse/Warehouse.vue'),
    meta: {
      pageTitle: 'Warehouse',
      service: 'e-commerce',
      resource: 'E-commerce-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/branch',
    name: 'e-commerce-branch-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/branch/Branch.vue'),
    meta: {
      pageTitle: 'Branch',
      service: 'e-commerce',
      resource: 'E-commerce-branch-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/online/store',
    name: 'e-commerce-online-store-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/onlineStore/OnlineStore.vue'),
    meta: {
      pageTitle: 'Online Store',
      service: 'e-commerce',
      resource: 'E-commerce-online-store-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/warehouse/transfer/list',
    name: 'e-commerce-product-transfer-list-warehouse',
    component: () => import('../../views/services/eCommerce/warehouse/productTransferList/ProductTransferList.vue'),
    meta: {
      pageTitle: 'Product transfer list',
      service: 'e-commerce',
      resource: 'E-commerce-product-transfer-list-warehouse',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/chat',
    name: 'e-commerce-chat',
    component: () => import('../../views/services/eCommerce/chat/ChatList.vue'),
    meta: {
      pageTitle: 'Chat',
      service: 'e-commerce',
      resource: 'E-commerce-chat',
      action: 'read',
    },
  },
  {
    path: '/e-commerce/chat/:id',
    name: 'e-commerce-chat-view',
    component: () => import('../../views/services/eCommerce/chat/ChatList.vue'),
    meta: {
      pageTitle: 'Chat View',
      service: 'e-commerce',
      resource: 'E-commerce-chat-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Chat',
          to: '/e-commerce/chat',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'e-commerce-chat',
    },
  },
  {
    path: '/e-commerce/feed',
    name: 'e-commerce-feed',
    component: () => import('../../views/services/eCommerce/feed/Feed.vue'),
    meta: {
      pageTitle: 'Feed',
      service: 'e-commerce',
      resource: 'E-commerce-feed',
      action: 'read',
    },
  },
]
